import React, { useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Helmet from 'react-helmet';
import Footer from '../../components/footer';
import NavBar from "../../components/navbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.scss';

const Contact = (data = {}) => {
    return (
        <>
            <Helmet>
                <title>{data.contact.pageTitle}</title>
                {
                    data.contact.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                        } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                        } else {
                            return null;
                        }
                    })
                }
            </Helmet>
            <NavBar {...data} />
            <div className="contact-container">
                <Hero {...data} />
                <ContactSection {...data} />
                <ContactLinks {...data} />
                <Footer {...data} />
            </div>
        </>
    )
}

const Hero = (props) => {
    const { contact } = props;
    return (
        <section className='section-global bg-shade-blue-light hero'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-tag mb-8">{contact.title}</div>
                        <h1 className='display-1'>{contact.subTitle}</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

const ContactSection = (props) => {
    const { contact } = props;
    const recaptchaRef = useRef(null);

    const initialValues = {
        full_name: '',
        company: '',
        email: '',
        subject: '',
        message: ''
    };

    const validationSchema = Yup.object().shape({
        full_name: Yup.string().required('Full name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            toast.error('Please complete the reCAPTCHA');
            setSubmitting(false);
            return;
        }

        setSubmitting(true);

        try {
            const response = await fetch(process.env.REACT_APP_CONTACT_FORM_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...values, recaptcha: recaptchaValue }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success('Form submitted successfully');
            resetForm();
            recaptchaRef.current.reset(); // Reset reCAPTCHA after form submission
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className='section-global'>
            <div className="container container-2">
                <div className="row gy-5">
                    <div className="col-lg-6">
                        <h2 className='mb-16'>{contact.form.title}</h2>
                        <p className="txt-1">{contact.form.description}</p>
                        {contact.form.promiseList.map((e, i) => (
                            <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>

<rect width="24" height="24" rx="12" fill="#6495ED"/>

<path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                                {e.text}
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6">
                        <ToastContainer />
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form className="row g-3">
                                    <div className="col-12">
                                        <label className="form-label">First and Last Name</label>
                                        <Field type="text" name="full_name" id="full_name" className="form-control" placeholder="First and last name" />
                                        <ErrorMessage name="full_name" component="div" className="error" />
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">Company Name</label>
                                        <Field type="text" name="company" id="company" className="form-control" placeholder="Rishi Shunak Company Ltd" />
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">Email</label>
                                        <Field type="email" name="email" id="email" className="form-control" placeholder="margaret@email.com" />
                                        <ErrorMessage name="email" component="div" className="error" />
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">Subject</label>
                                        <Field type="text" name="subject" id="subject" className="form-control" placeholder="Enter subject" />
                                        <ErrorMessage name="subject" component="div" className="error" />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-label">Your Message for us</label>
                                        <Field as="textarea" name="message" id="message" className="form-control" rows="4" placeholder="How can we help you?" />
                                        <ErrorMessage name="message" component="div" className="error" />
                                    </div>
                                    <div className="col-12">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={isSubmitting}>
                                            {isSubmitting ? <ClipLoader size={24} color="#ffffff" /> : 'Submit'}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
};

const ContactLinks = (props) => {
    const { contact } = props.shared;

    return (
        <section className='section-global bg-shade-blue'>
            <div className="container container-2">
                <div className="row gy-4">
                    <div className="col-lg-6 col-md-6">
                        <a href={`mailto:${contact.email1}`} className="contact-link">
                            <div className="contact-link-container d-flex align-items-center">
                                <img src={require(`./assets/contact_ico1.svg`).default} height="50" alt="Email" />
                                <div className='ms-3'>
                                    <div className="txt-2 fw-500 color-1">Email</div>
                                    <div className="txt-3">{contact.email1}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <a href={`tel:${contact.phone1}`} className="contact-link">
                            <div className="contact-link-container d-flex align-items-center">
                                <img src={require(`./assets/contact_ico2.svg`).default} height="50" alt="Phone" />
                                <div className='ms-3'>
                                    <div className="txt-2 fw-500 color-1">Phone</div>
                                    <div className="txt-3">{contact.phone1}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <a href={`${contact.addressMapLink2}`} className="contact-link">
                            <div className="contact-link-container d-flex align-items-center">
                                <img src={require('./assets/contact_ico3.svg').default} height="50" alt="Address" />
                                <div className='ms-3'>
                                    <div className="txt-2 fw-500 color-1">Remote Address</div>
                                    <div className="txt-3">{contact.address2}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <a href={`${contact.addressMapLink1}`} className="contact-link">
                            <div className="contact-link-container d-flex align-items-center">
                                <img src={require('./assets/contact_ico3.svg').default} height="50" alt="Address" />
                                <div className='ms-3'>
                                    <div className="txt-2 fw-500 color-1">Headquarters Address</div>
                                    <div className="txt-3">{contact.address1}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
