import { Accordion } from 'react-bootstrap'
import Helmet from 'react-helmet'
import NavBar from "../../components/navbar"
import Footer from '../../components/footer'
import './style.scss'
import ContactSection from '../../components/contactSection'

const FAQs = (data) => {
    return (
        <>
            <Helmet>
                <title>{data.faq.pageTitle}</title>
                {
                    data.faq.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <NavBar {...data}/>
            <div className="faqs-container">
                <Hero {...data}/>
                <FAQSection {...data}/>
                <ContactSection bg="bg-shade-blue" contact_data={data.shared.contact.cta}/>
                <Footer {...data}/>
            </div>
        </>
    )
}

const Hero = (props) => {

    const { faq } = props;
    return (
        <>
            <section className='section-global bg-shade-blue-light hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">{faq.title}</div>
                            <h1 className='display-1'>{faq.subTitle}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const FAQSection = (props) => {
    const { faq } = props;

    return (
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12">
                            <Accordion alwaysOpen flush>
                                {faq.itemList.map((e,i)=> 
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header>{e.titleText}</Accordion.Header>
                                        <Accordion.Body>
                                            {e.description}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQs